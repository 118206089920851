<div class="main mb-4 mt-4">
    <molla-page-header title="{{'trackYourOrder'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'trackYourOrder'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3">
        <div class="container" *ngIf="loaded">
            <div *ngIf="!order" class="form-box  mb-4 mt-4">
                <form method="GET">
                    <div class="form-group">
                        <label for="orderId">Order ID</label>
                        <input type="text" id="orderId" [(ngModel)]="orderId" name="orderId" class="form-control" required
                            placeholder="Enter your Order ID" [value]="orderId" />
                    </div>

                    <button type="submit" class="btn btn-primary btn-block" (click)="submit()">
                    {{'Submit'|translate}}
                    </button>
                </form>
            </div>
            <div *ngIf="order" class="form-box  mb-4 mt-4">
                <div class="order-status">
                    <h4>Order Status</h4>
                    <hr>
                    <p><strong>Status:</strong> {{ order['status_'+selected] }}</p>
                    <p><strong>Estimated Delivery:</strong> {{ order.deliveryDate }}</p>
                    <br><br>
                    <button type="submit" class="btn btn-primary btn-block" (click)="back()">
                        {{'trackAnother'|translate}}
                        </button>
                </div>
            </div>
        </div>
        <div class="mb-2"></div>
    </div>

</div>