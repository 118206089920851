<main class="main">
    <molla-page-header title="{{'contactus'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'contactus'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <div id="map" class="mb-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d893.0888394342526!2d50.05580651584846!3d26.44427199669518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fd1682144805%3A0xf370fe7f2ebdd73a!2zQVAgVG9vbHMgLSBBcmFiIFByb3MgVG9vbHMgZm9yIE1hY2hpbmVyeSBhbmQgRXF1aXBtZW50INi02LHZg9ipINin2YTZhdit2KrYsdmB2YjZhiDYp9mE2LnYsdioINmE2YTYp9mE2KfYqiDZiCDYp9mE2YXYudiv2KfYqg!5e0!3m2!1sen!2ssa!4v1734416601012!5m2!1sen!2ssa" width="100%" height="480" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="contact-box text-center">
                        <h3>{{'address'|translate}}</h3>
                        <address *ngIf="selected==='en'">Al Muhammadiya, Dammam <br> Saudi Arabia
                        </address>
                        <address *ngIf="selected==='ar'">
                            حي المحمدية ، الدمام <br>، المملكة العربية السعودية
                        </address>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contact-box text-center">
                        <h3>{{'Start a Conversation'|translate}}</h3>

                        <div><a href="mailto:support@aptools.sa"><i class="icon-envelope"></i> support@aptools.sa</a></div>
                        <div><a href="mailto:sales@aptools.sa"><i class="icon-envelope"></i> sales@aptools.sa</a></div>
                        <div><a href="mailto:info@aptools.sa"><i class="icon-envelope"></i> info@aptools.sa</a></div>
                        <div>
                            <a class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                            <a class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contact-box text-center">
                        <h3>{{'careers'|translate}}</h3>
                        <div>{{'sendYourCV'|translate}}</div>
                        <div><a href="mailto:careers@aptools.sa"><i class="icon-envelope"></i> careers@aptools.sa</a></div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contact-box text-center">
                        <h3>{{'Social'|translate}}</h3>

                        <div class="social-icons justify-content-center mb-0">
                            <a class="social-icon" target="_blank" href="https://m.facebook.com/ArabProTools/">
                                <i class="icon-facebook-f"></i>
                            </a>
                            <a class="social-icon" target="_blank" href="https://x.com/AP_Tools">
                                
                                <i class="icon-x"><img src="assets/images/icons/x.svg" alt="X icon"></i>
                            </a>
                            <a class="social-icon" target="_blank" href="https://www.instagram.com/arabprotools/"><i
                                    class="icon-instagram"></i>
                            </a>
                            <a class="social-icon" target="_blank"
                                href="https://www.linkedin.com/company/arab-pro-tools"><i class="icon-linkedin"></i>
                            </a>
                            <!-- <a class="social-icon" target="_blank" href="https://www.youtube.com/@arabprotools"><i
                                    class="icon-youtube"></i>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <hr class="mt-3 mb-5 mt-md-1"> -->
            <!-- <div class="touch-container row justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div class="text-center">
                        <h2 class="title mb-1">{{'GetInTouch'|translate}}</h2>
                    </div>
                    <form action="#" class="contact-form mb-2" >
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="cname" class="sr-only">{{'name'|translate}}</label>
                                <input type="text" class="form-control" id="cname" placeholder="{{'name'|translate}} *"
                                    required name="name" [(ngModel)]="contactUs.name">
                            </div>

                            <div class="col-sm-4">
                                <label for="cemail" class="sr-only">{{'email'|translate}}</label>
                                <input type="email" class="form-control" id="cemail"
                                    placeholder="{{'email'|translate}} *" required name="email"
                                    [(ngModel)]="contactUs.email">
                            </div>

                            <div class="col-sm-4">
                                <label for="cphone" class="sr-only">{{'mobile'|translate}}</label>
                                <input type="tel" class="form-control" id="cphone" placeholder="{{'mobile'|translate}}"
                                    name="mobile" [(ngModel)]="contactUs.mobile">
                            </div>
                        </div>

                        <label for="csubject" class="sr-only">{{'subject'|translate}}</label>
                        <input type="text" class="form-control" id="csubject" placeholder="{{'subject'|translate}}"
                            name="subject" [(ngModel)]="contactUs.subject">

                        <label for="cmessage" class="sr-only">{{'message'|translate}}</label>
                        <textarea class="form-control" cols="30" rows="4" id="cmessage" required
                            placeholder="{{'message'|translate}} *" name="message"
                            [(ngModel)]="contactUs.message"></textarea>

                        <div class="text-center">
                            <button type="button" (click)="SendMessage()"
                                class="btn btn-outline-primary-2 btn-minwidth-sm">
                                <span>SUBMIT</span>
                                <i class="icon-long-arrow-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</main>