<footer class="footer">
    <div class="footer-middle border-0">
        <div [ngClass]="containerClass">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="widget widget-about">
                        <!-- <a [href]="root.home()">
                            <img src="assets/images/apt_logo.svg" alt="Arab Pro Tools" class="footer-logo" height="27">
                        </a> -->

                        <h4 class="widget-title">{{'FollowUs' | translate}}</h4>
                        <div class="widget-call">
                            <i class="icon-whatsapp"></i>
                            {{'salesInquiries' | translate}}<br>
                            <a class="desktop mobileNumber" href="https://web.whatsapp.com/send?phone=966552822719">+966552822719</a>
                            <a class="mobile mobileNumber" href="whatsapp://send?phone=966552822719">+966552822719</a>
                        </div>

                        <div class="widget-call">
                            <i class="icon-envelope"></i>
                            {{'email' | translate}}<br>
                            <a href="mailto:sales@aptools.sa">sales@aptools.sa</a>
                        </div>
                        <div class="text-center mt-2">
                            <div class="social-icons justify-content-center mb-0">
                                <a class="social-icon" target="_blank" href="https://www.facebook.com/APTools.sa">
                                    <i class="icon-facebook-f"></i>
                                </a>
                                <a class="social-icon" target="_blank" href="https://x.com/AP_Tools">
                                    <!-- <i class="icon-twitter"></i> -->
                                    <i class="icon-x"><img src="assets/images/icons/x.svg" alt="X icon"></i>
                                </a>
                                <a class="social-icon" target="_blank" href="https://www.instagram.com/ap_tools_sa/"><i
                                        class="icon-instagram"></i>
                                </a>
                                <a class="social-icon" target="_blank"
                                    href="https://www.linkedin.com/company/arab-pro-tools"><i class="icon-linkedin"></i>
                                </a>
                                <!-- <a class="social-icon" target="_blank" href="https://www.youtube.com/@arabprotools"><i
                                        class="icon-youtube"></i>
                                </a> -->
                            </div>
                        </div>
                    </div>

                </div>





                <div class="col-sm-6 col-lg-3 mobile accordion-footer">
                    <molla-accordion id="accordion-footer">
                        <div class="widget">
                            <h4 class="widget-title" id="heading-footer1">
                                <a class="toggle-button collapsed" role="button" data-toggle="collapse"
                                    href="#collapse-footer1" aria-expanded="true" aria-controls="collapse-footer1">
                                    {{'information' | translate}}
                                </a>
                            </h4>
                            <ul class="widget-list" id="collapse-footer1" class="collapse"
                                aria-labelledby="heading-footer1" data-parent="#accordion-footer">
                                <li><a [href]="root.terms()">{{'termsAndCond' | translate}}</a></li>
                                <li><a [href]="root.faqs()">{{'faqs' | translate}}</a></li>
                                <li><a [href]="root.about()">{{'about' | translate}}</a></li>
                                <!-- <li><a [href]="root.contact()">{{'contactus' | translate}}</a></li> -->
                                <li><a [href]="root.storeLocator()">{{'StoreLocator' | translate}}</a></li>
                            </ul>
                        </div>
                        <div class="widget">
                            <h4 class="widget-title" id="heading-footer2">
                                <a class="toggle-button collapsed" role="button" data-toggle="collapse"
                                    href="#collapse-footer2" aria-expanded="true" aria-controls="collapse-footer2">
                                    {{'policies' | translate}}
                                </a>
                            </h4>
                            <ul class="widget-list" id="collapse-footer2" class="collapse"
                                aria-labelledby="heading-footer2" data-parent="#accordion-footer">
                                <li><a [href]="root.privacyPolicy()">{{'privacyPolicy' | translate}}</a></li>
                                <!-- <li><a [href]="root.paymentMethods()">{{'paymentMethods' | translate}}</a></li> -->
                                <!-- <li><a [href]="root.pricingPolicy()">{{'pricingPolicy' | translate}}</a></li> -->
                                <li><a [href]="root.returnPolicy()">{{'returnPolicy' | translate}}</a></li>
                                <li><a [href]="root.shippingPolicy()">{{'shippingPolicy' | translate}}</a></li>
                            </ul>
                        </div>
                        <div class="widget">
                            <h4 class="widget-title" id="heading-footer3">
                                <a class="toggle-button collapsed" role="button" data-toggle="collapse"
                                    href="#collapse-footer3" aria-expanded="true" aria-controls="collapse-footer3">
                                    {{'contactus' | translate}}
                                </a>
                            </h4>
                            <ul class="widget-list" id="collapse-footer3" class="collapse"
                                aria-labelledby="heading-footer3" data-parent="#accordion-footer" *ngIf="selected==='en'">
                                <li> <a target="_blank" href="https://maps.google.com/maps?ll=26.444272,50.056849&z=18&t=m&hl=en&gl=SA&mapclient=embed&cid=17541800370806576954">{{ address_en}}</a> </li>
                                <li><a [href]="'mailto:'+emailAddress">{{ emailAddress }}</a></li>
                                <li><a [href]="'mailto:'+emailAddressInfo">{{ emailAddressInfo }}</a></li>
                                <li>{{ hours_en }}</li>
                            </ul>
                            <ul class="widget-list" id="collapse-footer3" class="collapse"
                                aria-labelledby="heading-footer3" data-parent="#accordion-footer" *ngIf="selected==='ar'">
                                <li> <a target="_blank" href="https://maps.google.com/maps?ll=26.444272,50.056849&z=18&t=m&hl=en&gl=SA&mapclient=embed&cid=17541800370806576954">{{ address_ar}}</a> </li>
                                <li><a [href]="'mailto:'+emailAddress">{{ emailAddress }}</a></li>
                                <li><a [href]="'mailto:'+emailAddressInfo">{{ emailAddressInfo }}</a></li>
                                <li>{{ hours_ar }}</li>
                            </ul>
                        </div>



                    </molla-accordion>
                </div>


                <div class="col-sm-6 col-lg-3 desktop">
                    <div class="widget">
                        <h4 class="widget-title">{{'information' | translate}}</h4>

                        <ul class="widget-list">
                            <li><a [href]="root.terms()">{{'termsAndCond' | translate}}</a></li>
                            <li><a [href]="root.faqs()">{{'faqs' | translate}}</a></li>
                            <li><a [href]="root.about()">{{'about' | translate}}</a></li>
                            <!-- <li><a [href]="root.contact()">{{'contactus' | translate}}</a></li> -->
                            <li><a [href]="root.storeLocator()">{{'StoreLocator' | translate}}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 desktop">
                    <div class="widget">
                        <h4 class="widget-title">{{'policies' | translate}}</h4>

                        <ul class="widget-list">
                            <li><a [href]="root.privacyPolicy()">{{'privacyPolicy' | translate}}</a></li>
                            <!-- <li><a [href]="root.paymentMethods()">{{'paymentMethods' | translate}}</a></li> -->
                            <!-- <li><a [href]="root.pricingPolicy()">{{'pricingPolicy' | translate}}</a></li> -->
                            <li><a [href]="root.returnPolicy()">{{'returnPolicy' | translate}}</a></li>
                            <li><a [href]="root.shippingPolicy()">{{'shippingPolicy' | translate}}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 desktop">
                    <div class="widget">
                        <a [href]="root.contact()">
                            <h4 class="widget-title">{{'contactus' | translate}}</h4>
                        </a>

                        <ul class="widget-list" *ngIf="selected==='en'">
                            <li> <a target="_blank" href="https://maps.google.com/maps?ll=26.444272,50.056849&z=18&t=m&hl=en&gl=SA&mapclient=embed&cid=17541800370806576954">{{ address_en}}</a> </li>
                            <li><a [href]="'mailto:'+emailAddress">{{ emailAddress }}</a></li>
                            <li><a [href]="'mailto:'+emailAddressInfo">{{ emailAddressInfo }}</a></li>
                            <li>{{ hours_en }}</li>
                        </ul>
                        <ul class="widget-list" *ngIf="selected==='ar'">
                            <li> <a target="_blank" href="https://maps.google.com/maps?ll=26.444272,50.056849&z=18&t=m&hl=en&gl=SA&mapclient=embed&cid=17541800370806576954">{{ address_ar}}</a> </li>
                            <li><a [href]="'mailto:'+emailAddress">{{ emailAddress }}</a></li>
                            <li><a [href]="'mailto:'+emailAddressInfo">{{ emailAddressInfo }}</a></li>
                            <li>{{ hours_ar }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div [ngClass]="containerClass">
            <p class="footer-copyright" *ngIf="selected==='en'">{{ copyrights_en }}</p>
            <p class="footer-copyright" *ngIf="selected==='ar'">{{ copyrights_ar }}</p>
            <a href="https://qr.mc.gov.sa/sales/license-details?qr=Zw25hYE9A9vqbd211ypgGQ%3D%3D" target="_blank" style="margin: 0 auto;">
                <img src="assets/images/sales_badge_ministry_of_commerce.png" alt="Arab Pro Ministry of Commerce" style="height:64px;">
            </a>
            <a href="https://eauthenticate.saudibusiness.gov.sa/inquiry?certificateRefID=0000010995" target="_blank" style="margin: 0 auto;">
                <img src="assets/images/Saudi_Business_Center.png" alt="Arab Pro Saudi Business Center" style="height:28px;">
            </a>
            <figure class="footer-payments">
                <img src="assets/images/payments.jpg" alt="Arab Pro Tools Payment methods" style="height:28px;">
            </figure>
        </div>
    </div>
    <div class="mb-4 mobile"></div>
    <div class="mb-10" *ngIf="isBottomSticky"></div>
</footer>
<app-stickymenu class="mobile"></app-stickymenu>