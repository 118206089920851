<div class="main">
  <molla-page-header title="{{'about'| translate}}" subtitle="{{'about_subtitle'|translate}}"></molla-page-header>
  <nav class="breadcrumb-nav mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [href]="root.home()">{{'home' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'about'| translate}}</li>
      </ol>
    </div>
  </nav>
  <!-- <div class="container"> -->
  <!-- <img class="page-header-big" src="assets/images/about/arabpro_aboutus_desktop.webp"> -->
  <!-- <div class="page-header page-header-big text-center desktop"
              style="background-image: url('assets/images/about/arabpro_aboutus_desktop.jpg')"></div><div class="page-header page-header-big text-center mobile" style="background-image: url('assets/images/about/arabpro_aboutus_mobile.jpg')"></div> -->
  <!-- </div> -->
  <div class="page-content pb-4 pt-4 ">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 mobile">
          <img src="assets/images/about/Arab_Pro_Company_History.webp" alt="About" class="about-img-front" />
        </div>
        <div class="col-lg-8" *ngIf="selected==='en'" style="padding:4rem;">
          <!-- <h2 class="title">Crafting Success with Every Tool</h2> -->
          <p class="mb-2">

            AP Tools is a leading provider of high-quality industrial equipment and solutions, renowned for delivering
            reliable and innovative products across diverse industries including manufacturing, construction, and
            energy.
            <br><br>
            Our dedicated team of experienced professionals is committed to understanding the unique needs of our
            clients. We provide tailored solutions that empower them to achieve their goals, from specialized machinery
            to comprehensive maintenance and support services.
          </p>
        </div>
        <div class="col-lg-8" *ngIf="selected==='ar'" style="padding:4rem;">
          <h2 class="title">نصنع النجاح مع كل أداة</h2>
          <p class="mb-2">
            شركة AP Tools هي المزود الرائد للمعدات الصناعية عالية الجودة والحلول المبتكرة، والمعروفة بتقديم منتجات
            موثوقة ومتميزة تخدم مختلف الصناعات بما في ذلك التصنيع والبناء والطاقة.
            <br><br>
            فريقنا المتميز من المحترفين ذوي الخبرة ملتزم بفهم الاحتياجات الفريدة لعملائنا. نحن نوفر حلولًا مخصصة تمكّنهم
            من تحقيق أهدافهم، بدءًا من الآلات المتخصصة وصولًا إلى خدمات الصيانة والدعم الشاملة.
          </p>
        </div>
        <div class="col-lg-4 desktop">
          <img src="assets/images/about/Arab_Pro_Company_History.webp" alt="About" class="about-img-front" />
        </div>
      </div>
    </div>
  </div>
  <div class="page-content pb-6 pt-6 ">
    <div class="container">
      <div class="row" *ngIf="selected==='en'">
        <div class="col-lg-6">
          <h2 class="title">Vision</h2>
          <p>To be the global leader in innovative, sustainable industrial equipment solutions that empower our
            customers to achieve unparalleled operational excellence, safety, and environmental responsibility.</p>
        </div>
        <div class="col-lg-6">
          <h2 class="title">Mission</h2>
          <p>At AP Tools, our mission is to be the trusted partner of choice, empowering our clients across the Middle
            East and North Africa with superior industrial equipment and customized solutions that optimize operations,
            drive sustainable growth, and position them for long-term success.</p>
        </div>
      </div>
      <div class="row" *ngIf="selected==='ar'">
        <div class="col-lg-6">
          <h2 class="title">رؤيتنا</h2>
          <p>أن نكون الرائدين عالميًا في تقديم حلول مبتكرة ومستدامة للمعدات الصناعية تمكن عملاءنا من تحقيق التميز
            التشغيلي والسلامة والمسؤولية البيئية على نحو غير مسبوق.</p>
        </div>
        <div class="col-lg-6">
          <h2 class="title">مهمتنا</h2>
          <p>في شركة AP Tools، تكمن رسالتنا في أن نكون الشريك الموثوق والمفضل، حيث نمكّن عملاءنا في منطقة الشرق الأوسط
            وشمال أفريقيا من خلال تقديم معدات صناعية فائقة الجودة وحلول مخصصة تعمل على تحسين العمليات، وتعزيز النمو
            المستدام، ووضعهم على طريق النجاح طويل الأمد.</p>
        </div>
      </div>
    </div>
  </div>





  <!-- <div class="page-content pb-3 bg-light-2 pt-5">
      <div class="container">
        <h2 class="title text-center mb-4">{{'salesDept'|translate}}</h2>
        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.retail()">
              <div class="member member-2 text-center">
                  <figure class="member-media">
                    <img src="assets/images/about/retail_m.webp" alt="{{'Retail Stores'|translate}}">
                    <figcaption class="member-overlay"></figcaption>
                  </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Retail Stores'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.dealers()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/wholesale_m.webp" alt="{{'Dealers'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Dealers'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.online()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/online_m.webp" alt="{{'Online Store'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Online Store'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.projects()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/projects_m.webp" alt="{{'Projects'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Projects'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> -->

  <div class="container mb-5 bg-light-2 text-center pt-5">
    <h2 class="title">SPECIALTIES & PRODUCT CATEGORIES</h2>
    <div class="row elements">
      <div class="col-xl-5col col-lg-3 col-md-3 col-6" *ngFor="let i of items">
        <div class="element">
          <img [src]="'assets/images/about/icons/'+i.img" class="elementimg">
          <p>{{i['title_'+selected]}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-5">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="brands-text text-center mx-auto mb-6">
          <h2 class="title">ACHIEVEMENT PARTNERS</h2>
        </div>

        <div class="brands-display">
          <div class="row justify-content-center">
            <div class="col-6 col-sm-4 col-md-3" *ngFor="let brand of brands | slice: 0 : 8">
              <a href="javascript:;" class="brand2">
                <img [src]="brand.image" alt="{{brand.name}}">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>
  <div class="container mb-5 mt-5">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="brands-text text-center mx-auto mb-6">
          <h2 class="title">CLIENTS</h2>
        </div>

        <div class="brands-display">
          <div class="row justify-content-center">
            <div class="col-6 col-sm-4 col-md-3" *ngFor="let client of clients | slice: 0 : 8">
              <a href="javascript:;" class="brand2">
                <img [src]="client.image" alt="{{client.name}}">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<hr>

  <div class="container mt-5 mb-5">
    <h5 class="mb-4">Sister Companies</h5>
    <div class="row">
      <div class="col-4">
        <a href="https://khusheim.com/" target="_blank">
          <img class="img-fluid sister-logo" src="assets/images/about/khusheim_logo.png" alt="APTools logo">
        </a>
      </div>
      <div class="col-4">
        <a href="https://chemco-pros.com/" target="_blank">
          <img class="img-fluid sister-logo" src="assets/images/about/chemco_logo.png" alt="CHEMCO logo">
        </a>
      </div>
      <div class="col-4">
        <a href="https://rowadalmadar.com/" target="_blank">
          <img class="img-fluid sister-logo" src="assets/images/about/rowadalmadar.png"
            alt="Rowad Al Madar logo">
        </a>
      </div>
    </div>
  </div>

</div>