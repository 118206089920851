<div class="main">
    <molla-page-header title="{{'privacyPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'maintenanceAndRepair'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <h4>{{'MaintenancePageText1'|translate}}</h4>
                <div style="width: 100%;text-align: center;">
                    <img src="../../../assets/images/rowadalmadar.png" alt="" style="height:100px;margin: 5px auto 20px;">
                </div>
                <p>{{'MaintenancePageText2'|translate}}</p>
                <p>{{'MaintenancePageText3'|translate}}</p>
                <p>
                    <strong> {{'MaintenancePageText4'|translate}}:</strong><br>
                    <a href="https://rowadalmadar.com" target="_blank">rowadalmadar.com</a> <br> 
                    <a href="mailto:info@rowadmmc.com">info&#64;rowadmmc.com</a>  
                </p>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            
        </div>
        <div class="mb-2"></div>
    </div>
</div>