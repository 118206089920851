<div class="main">
    <molla-page-header title="{{'Brands'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'Brands'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content ">
        <div class="container" >
            <div class="row p-4">
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('makita')" class="brand">
                        <img src="../../../assets/images/brands/makita.png" alt="makita">
                    </a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('bishamon')" class="brand">
                        <img src="../../../assets/images/brands/bishamon.png" alt="bishamon">
                    </a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('euromatic')" class="brand">
                        <img src="../../../assets/images/brands/euromatic.png" alt="euromatic"></a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('kc-power')" class="brand">
                        <img src="../../../assets/images/brands/kcpower.png" alt="kcpower"></a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('leo')" class="brand">
                        <img src="../../../assets/images/brands/leo.png" alt="leo"></a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('kito')" class="brand">
                        <img src="../../../assets/images/brands/kito.png" alt="kito"></a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('stony')" class="brand">
                        <img src="../../../assets/images/brands/stony.png" alt="stony"></a>
                </div>
                <div class="brandContainer col-3 cols-sm-4">
                    <a [href]="root.brand('stabila')" class="brand">
                        <img src="../../../assets/images/brands/STABILA.png" alt="STABILA"></a>
                </div>
            </div>
            <div class="mb-2"></div>
            <div class="container" style="padding-bottom: 10px;" contentAnim>
                <div class="details">
                    <img src="../../../assets/images/brands/makita.png" alt="makita">
                    <p>{{'makitaBrand' | translate}}</p>
                </div>
                <div class="details">
                    <img src="../../../assets/images/brands/bishamon.png" alt="bishamon">
                    <p>{{'bishamonBrands' | translate}}</p>
                </div>
                <div class="details">
                    <img src="../../../assets/images/brands/kcpower.png" alt="kcpower">
                    <p>{{'kcBrand' | translate}}</p>
                </div>
                
            </div>
        </div>

    </div>
</div>