<main class="main">
    <div class="page-content" data-parallax class="bg-parallax">
        <div class="container">
                <div class="row justify-content-center content-loading" style="margin:20px auto 0;padding-top:10px" *ngIf="loaded">
                    <!-- <div class="preloader"></div> -->
                        <div class="icon-box icon-box-circle text-center">
                            <span class="icon-box-icon">
                                <i class="icon-check"></i>
                            </span>
                            <div class="icon-box-content">
                                <h3 class="icon-box-title">{{'returnSuccess' | translate}}</h3>
                                <br>
                                <p>
                                    {{'orderNumber' | translate}} <b> #{{ order.id }}</b> , 
                                    {{'createdAt' | translate}} <b> {{ order.created_date }}</b> , 
                                    {{'paymentMethod' | translate}} <b>{{ order.payment_method }} </b>
                                </p>
                            </div>
                            <br>
                            <a [href]="root.shop()" class="btn btn-outline-primary btn-sm"><span>{{'ContinueShopping' | translate}}</span><i class="icon-refresh"></i></a>&nbsp;
                            <div *ngIf="payment_text && selected === 'en'" class="bankTransferTxt">
                                <div class="payment_text_header">
                                    <img [src]="'../../../../assets/images/payments/bank_transfer.svg'" class="payments-img" alt="" align="left">
                                    <h3>Bank Transfer Information</h3>
                                </div>
                                <p>
                                    To process your order please complete the following steps:
                                    <br> 1- transfer the payment APT bank account: 
                                    <br>Account Name: <b>ARAB PROS MACHINERY EQUIPMENT CO</b>
                                    , Bank: ANB Bank
                                    , IBAN: <b>SA7230400108095469690047</b>
                                    <br>2- Include your Order ID in the notes section
                                    <br>3- send a copy of the transfer confirmation to 
                                    <a href="mailto:support@aptools.sa" target="_blank"><i class="icon-envelope"></i> support@aptools.sa</a> 
                                     , <a class="desktop-init" href="https://web.whatsapp.com/send?phone=966552822719" target="_blank"><i class="icon-whatsapp"></i> 0552822719</a>
                                     <a class="mobile-init" href="whatsapp://send?phone=966552822719" target="_blank"><i class="icon-whatsapp"></i> 0552822719</a> 
                                    or <b>APT Livechat</b>
                                    <br>Once Bank transfer is verified your order will be processed within 1 business day, Thank you for your purchase.
                                </p>
                            </div>
                            <div *ngIf="payment_text && selected === 'ar'" class="bankTransferTxt">
                                <div class="payment_text_header">
                                    <img [src]="'../../../../assets/images/payments/bank_transfer.svg'" class="payments-img" alt="" align="right">
                                    <h3>بيانات التحويل البنكي</h3>
                                </div>
                                <p>
                                    شكرًا لك، تم إرسال تفاصيل التحويل إلى بريدك الإلكتروني 
                                    لإتمام طلبك يرجى إكمال الخطوات التالية:
                                    <br> 1- تحويل مبلغ الفاتورة إلى حساب بنك: 
                                    <br>إسم الحساب: <b>ARAB PROS MACHINERY EQUIPMENT CO</b>
                                    , البنك العربي
                                    , رقم الحساب: <b>SA7230400108095469690047</b>
                                    <br>2- قم بإضافة رقم الطلب في قسم الملاحظات
                                    <br>3- إرسال نسخة من إيصال التحويل إلى 
                                    <a href="mailto:support@aptools.sa" target="_blank"><i class="icon-envelope"></i> support@aptools.sa</a> 
                                     , <a class="desktop-init" href="https://web.whatsapp.com/send?phone=966552822719" target="_blank"><i class="icon-whatsapp"></i> 0552822719</a>
                                     <a class="mobile-init" href="whatsapp://send?phone=966552822719" target="_blank"><i class="icon-whatsapp"></i> 0552822719</a> 
                                    or <b>APT Livechat</b>
                                    <br>بمجرد التحقق من التحويل البنكي، ستتم معالجة طلبك خلال يوم عمل. 
                                    شكرا لكم .
                                </p>
                                
                            </div>
                        </div>
                </div>


                    <div class="row checkout justify-content-center content-loading" *ngIf="order">
                        <div class="col-lg-8" *ngIf="order.items">
                            <h2 class="checkout-title">{{'YourOrder' | translate}}</h2>
                            <div class="checkout-card">
                                <ng-container *ngFor="let item of order.items">
                                    <div class="checkout-card-items" >
                                        <figure class="card-img product-media">
                                            <molla-image *ngIf="item.img && item.img!='null'" [src]="item.img" alt="Product"></molla-image>
                                            <molla-image *ngIf="item.GoldenWarrantyTransID" src="../../../../assets/images/warranty_plus.png" alt="Golden Warranty"></molla-image>
                                        </figure>
                                        <div class="card-info">
                                            <div class="card-desc">
                                                <h3 *ngIf="item.title_en">({{ item.qty }}) {{item['title_'+selected]}}</h3>
                                                <h3 *ngIf="item.GoldenWarrantyTypeID === '1'">({{ item.qty }})  {{'golden12_desc' | translate}}</h3>
                                                <h3 *ngIf="item.GoldenWarrantyTypeID === '2'">({{ item.qty }})  {{'golden24_desc' | translate}}</h3>
                                                <h3  *ngIf="item.GoldenWarrantyTransID">({{ item.qty }}) {{'freeGoldenWarranty' | translate}}</h3>
                                            </div>
                                            <div class="price">
                                                {{ item.subtotal * 1.15 |currencyFormat}} 
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <aside class="col-lg-4">
                            <h2 class="checkout-title">{{'orderDetails'|translate}}</h2>
                            <div class="checkout-card">
                                <div class="checkout-card-totals">
                                    <div class="checkout-card-row">
                                        <div class="checkout-card-col">{{'ShippingMethod' | translate}} <span>{{order['shipping_' + selected] }}</span></div> 
                                        <div class="checkout-card-col">{{ order.shippingFee * 1.15 | currencyFormat }}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.codFee > 0">
                                        <div class="checkout-card-col">{{'CashOnDeliveryFee' | translate}}</div> 
                                        <div class="checkout-card-col">{{ order.codFee * 1.15 |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.subtotal">
                                        <div class="checkout-card-col">{{'subtotal' | translate}} </div> 
                                        <div class="checkout-card-col">{{ order.subtotal|currencyFormat }}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.coupon_discount != null && order.coupon_discount != 0">
                                        <div class="checkout-card-col">{{'coupon_discount' | translate}}</div> 
                                        <div class="checkout-card-col">{{ order.coupon_discount |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.bundle_discount != null && order.bundle_discount != 0">
                                        <div class="checkout-card-col">{{'bundle_discount' | translate}}</div> 
                                        <div class="checkout-card-col">{{ order.bundle_discount |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.from_wallet != null && order.from_wallet != 0">
                                        <div class="checkout-card-col">{{'wallet_balance' | translate}}</div> 
                                        <div class="checkout-card-col">{{ order.from_wallet |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row" *ngIf="order.voucher_discount > 0">
                                        <div class="checkout-card-col">{{'coupon_discount' | translate}}</div> 
                                        <div class="checkout-card-col">- {{ order.voucher_discount |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row">
                                        <div class="checkout-card-col">{{'vat' | translate}}</div> 
                                        <div class="checkout-card-col">{{ order.vat |currencyFormat}}</div> 
                                    </div>
                                    <div class="checkout-card-row">
                                        <div class="checkout-card-col"><b>{{'total' | translate}}</b>  <span> ({{'vatInclusive' | translate}})</span></div> 
                                        <div class="checkout-card-col"><b>{{ order.total|currencyFormat }}</b></div> 
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>


        </div>
    </div>
</main>