import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Params } from 'src/app/shared/interfaces/params';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AuthService } from 'src/app/shared/services/auth.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
	selector: 'contracts-page',
	templateUrl: './contracts.component.html',
	styleUrls: ['./contracts.component.scss'],
})

export class ContractsComponent implements OnInit {
	products = [];
	user = [];
	contract = [];
	pageTitle = '';
	toggle = false;
	loaded = false;
	firstLoad = false;
	params:Params = new Params();
	total:number;
	limit:number;
	type='6cols';
	filters = [];
	selected;
	errorMsg = '';
	succMsg = '';
	otherLang;
	searchTerm:string = '';
	languages = [
		{ name: 'English', code: 'en', direction: 'ltr', image: 'en.svg' },
		{ name: 'العربية', code: 'ar', direction: 'rtl', image: 'ar.svg' }
	];
	filteredProducts = [];
	direction;
	token = '';
	constructor(
		public activeRoute: ActivatedRoute,
		public router: Router, 
		public utilsService: UtilsService, 
		public auth: AuthService,
		private lang: LanguageService,
		public cartService:CartService,
		private alert:AlertService
		) {
			this.selected = this.lang.selected;
			this.activeRoute.params.subscribe(p => {
				this.token = p.token;
				this.auth.contractsLinkLogin({'token':p.token}).subscribe(res=>{
					if(!res.data){
						this.errorMsg = res['msg_'+this.selected]
					}else if(parseInt(res.login['orderCreated']) === 1){
						this.succMsg = this.selected === 'ar' ?  'تم الطلب بنجاح و جاري معالجته.' : 'Order created and under processing.';
					}else{
						this.products = res.products;
						this.user = res.login;
						this.contract = res.contract;
						this.filteredProducts = res.products;
					}
					this.loaded = true;
					console.log(res);
				});
				
				this.activeRoute.queryParams.subscribe(parameters => {
					if (parameters['page']) {
						this.params.page = parameters['page'];
					}
				})
			});
	}

	ngOnInit(): void {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
		this.otherLang = this.selected === 'en' ? this.languages[1] : this.languages[0];
		this.direction = this.selected === 'ar' ? 'left' : 'right';
	}
	@HostListener('window: resize', ['$event'])
	onResize(event: Event) {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}

	changeOrderBy(event: any) {
		this.router.navigate([], { queryParams: { sort: event.currentTarget.value, page: 1 }, queryParamsHandling: 'merge' });
	}
	changeLanguage(lng) {
		let oldLng = lng === 'en' ? 'ar' : 'en';
		let link = this.router.url.toString().split('?')[0];
		let parameters = this.router.url.toString().split('?')[1] ? '?' + this.router.url.toString().split('?')[1] : '';
		link = link === '/' ? '' : link;
		if (link.includes('/' + oldLng)) {
			this.router.navigateByUrl(link.replace('/' + oldLng, '/' + lng) + parameters);
		} else if (link.includes('/' + lng)) {
			// do nothing encodeURIComponent
		} else {
			this.router.navigateByUrl('/' + lng + link + parameters);
		}
	}
	// search 
	showSearchForm(e: Event) {
		document.querySelector('.header .header-search').classList.add('show');
		e.stopPropagation();
	}
	searchProducts(){
		this.filteredProducts = this.products.filter(product => {
			return product.title_en.toLowerCase().includes(this.searchTerm.toLowerCase());
		});
	}
	createOrder(){
		this.loaded = false;
		let data = {
			'user': this.user['user'],
			'token': this.token,
			'contract': this.contract['id'],
			'total': this.cartService.subtotal,
			'items': []
		};
		for (var i of this.cartService.items) {
			let price = Number(i.product.price);
			data.items.push({
			  product: i.product.id,
			  weight: i.product.weight,
			  title_en: i.product.title_en,
			  slug: i.product.slug,
			  qty: i.quantity,
			  subtotal: price * i.quantity,
			  price: price ,
			  vat: this.getVat(price * i.quantity),
			});
		}
		
		this.auth.contractsCreateOrder(data).subscribe(res =>{
			this.loaded = true;
			if (!res.data) {
				this.alert.showError(res["msg_" + this.selected]);
			}else{
				this.alert.showSuccess(res["msg_" + this.selected]);
				this.cartService.empty().subscribe((_) => { 
					window.location.reload();
				});
			}
		});
		// this.cartService.total$.subscribe((res) => {
		// 	const total = res;
		//   });
	}
	removeFromCart(event: Event, item: any) {
		this.cartService.remove(item).subscribe({ complete: () => {} });
	}
	getVat(price: number) {
		return (price * 0.15);
	  }
}