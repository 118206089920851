import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { DirectionService } from 'src/app/shared/services/direction.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UserService } from 'src/app/shared/services/user.service';
import { NewsLetter } from 'src/app/shared/interfaces/news-letter';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RootService } from 'src/app/shared/services/root.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { JsonService } from 'src/app/shared/services/json.service';

@Component({
	selector: 'molla-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
	products = [];
	topProducts = [];
	newProducts = [];
	loaded = false;
	featuredLoaded = false;
	introSlider: OwlOptions = {
		items: 1,
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		margin: 0,
		navSpeed: 600,
		navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
		nav: true,
		autoplay: true,
		autoplayTimeout: 15000,
		rtl: this.direction.isRTL()
	};
	featuresSlider: OwlOptions = {
		items: 1,
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		margin: 0,
		navSpeed: 1000,
		// navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
		// nav: true,
		autoplay: true,
		autoplayTimeout: 3000,
		rtl: this.direction.isRTL()
	};
	brandSlider: OwlOptions = {
		items: 8.5,
		loop: true,
		margin: 20,
		nav: false,
		dots: false,
		smartSpeed: 400,
		autoplay: true,
		autoplayTimeout: 15000,
		responsive: {
			0: {
				items: 2.5
			},
			420: {
				items: 4.5
			},
			600: {
				items: 5.5
			},
			900: {
				items: 6.5
			},
			1024: {
				items: 7.5
			},
			1360: {
				items: 8.5,
				margin: 30
			}
		},
		rtl: this.direction.isRTL()
	};
	categoriesSlider: OwlOptions = {
		items: 10,
		navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
		smartSpeed: 400,
		autoplayTimeout: 15000,
		nav: false,
		dots: false,
		margin: 20,
		autoplay: false,
		loop: true,
		responsive: {
			0: {
				items: 3.5,
			},
			576: {
				items: 5.5
			},
			768: {
				items: 7.5
			},
			992: {
				items: 8.5
			},
			1200: {
				items: 10,
				loop: false
			}
		},
		rtl: this.direction.isRTL()
	};
	slides = [];
	brands = [];
	featuredProducts = [];
	onlinex = [];
	selected: string;
	popularCategories: any[];
	trendingProducts = [];
	trendingCategories = [];
	newsletter: NewsLetter = new NewsLetter();
	subscribeLoaded = true;
	constructor(
		public root: RootService,
		public utilsService: UtilsService,
		private auth: AuthService,
		private userService: UserService,
		private lang: LanguageService,
		private direction: DirectionService,
		private sessionService: SessionService,
		private alertService: AlertService,
		private jsonService:JsonService
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		let user = this.sessionService.getUser();
		if (user) {
			this.newsletter.email = user.email;
		}

		// slides
		this.auth.getSlides().subscribe(res => {
			this.slides = res;
		});
		// Popular categories
		this.auth.getPopularCategories().subscribe(res => {
			this.popularCategories = res;
		});
		// Brands
		this.auth.getPopularBrands().subscribe(res => {
			this.brands = res;
		});
		// featured products
		this.auth.getFeaturedProducts({}).subscribe(res => {
			this.featuredProducts = res;
			this.featuredLoaded = true;
		});
		this.auth.getOnlineExclusive({}).subscribe(res => {
			this.onlinex = res;
			this.loaded = true;
		});
		this.auth.getTrendingProducts({}).subscribe(res => {
			this.trendingProducts = res.products;
			this.trendingCategories = res.categories;
			this.loaded = true;
		});
		this.jsonService.removeStructuredData();
		this.jsonService.insertSchema();
	}
	subscribe() {
		this.subscribeLoaded = false;
		this.userService.subscribe(this.newsletter).subscribe((result: any) => {
			this.subscribeLoaded = true;
			if (result.data == true) {
				return this.alertService.showSuccess(result.msg_en);
			} else {
				return this.alertService.showError(result.msg_en);
			}
		});
	}
}
