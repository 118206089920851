import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-track-page',
	templateUrl: './track.component.html',
	styleUrls: ['./track.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TrackPageComponent implements OnInit {
	selected;
	order;
	orderId:string = '';
	loaded = true;
	constructor(
		public sanitizer: DomSanitizer,
		private lang : LanguageService,
		public root:RootService,
		private route: ActivatedRoute,
		private auth: AuthService,
		private router: Router,
		private alert:AlertService,
		private translate:TranslateService,
		private cdr: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		// Subscribe to route parameters
		this.route.params.subscribe(data => {
			if (data.orderId) {
				this.orderId = data.orderId;
				this.trackOrder(this.orderId); // Call trackOrder with the provided orderId
			}
		});
	}

	// Mock function to track order
	trackOrder(id) {
		this.loaded = false;
		this.auth.trackOrder({ 'id': id }).subscribe(res => {
			this.loaded = true;
			console.log(res);
			if(res.data){
				this.order = res.data;
				this.cdr.detectChanges();
			}else{
				this.alert.showError(res['msg_'+this.selected]);
			}
		});
	}
	submit(){
		console.log('submitted:'+this.orderId+this.loaded);
		if (!this.loaded) {
			return;
		}
		if(!this.orderId){
			this.alert.showError(this.translate.instant('trackOrderFailed'));
		}
		this.router.navigate(["track/"+this.orderId]).then(() => {
			location.reload();
		});
	}
	back(){
		this.router.navigate(["track"]).then(() => {
			location.reload();
		});
	}
}
