import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/interfaces/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
	selector: 'return-success-page',
	templateUrl: './return-success.component.html',
	styleUrls: ['./return-success.component.scss']
})

export class ReturnSuccessComponent implements OnInit {
	order: any;
	loaded = false;
	selected: string;
	isBankTransfer = false;
	user: User;
	window: Window;
	payment_text: string;
	orderId = 0;
	constructor(
		private lang: LanguageService,
		private session: SessionService,
		private router: Router,
		public root:RootService,
		private route: ActivatedRoute,
		private auth:AuthService
	) {
		this.user = this.session.getUser();
		if (this.user == null) {
			this.router.navigateByUrl("/account/login");
		}
	}
	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.route.params.subscribe(data => {
			this.orderId = data.orderId;
			this.auth.getOrderDetails({ 'id': this.orderId }).subscribe(res => {
				this.loaded = true;
				this.order = res;
			});
		});
	}
}
