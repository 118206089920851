import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class RootService {
    basePath = '';
    selected = 'en';
    constructor(
        private router: Router,
        private lang: LanguageService
    ) {
        this.selected = this.lang.selected;
        this.basePath = '/' + this.selected + '/';
    }

    home(): string {
        return this.basePath;
    }

    shop(): string {
        return this.basePath + 'shop';
    }

    brand(slug): string {
        return this.basePath + 'brand/' + slug;
    }
    search(qry: String): string {
        return this.shop() + '?qry=' + qry;
    }
    category(slug): string {
        return this.basePath + 'category/' + slug;
    }
    bunch(slug): string {
        return this.basePath + 'bunch/' + slug;
    }

    product(slug): string {
        return this.basePath + 'product/' + slug;
    }

    cart(): string {
        return this.basePath + 'shop/cart';
    }
    quote(): string {
        return this.basePath + 'quote';
    }
    about(): string {
        return this.basePath + 'about-us';
    }
    retail(): string {
        return this.basePath + 'retail';
    }
    online(): string {
        return this.basePath + 'online';
    }
    dealers(): string {
        return this.basePath + 'dealers';
    }
    projects(): string {
        return this.basePath + 'projects';
    }
    contact(): string {
        return this.basePath + 'contact-us';
    }
    storeLocator(): string {
        return this.basePath + 'stores-location';
    }

    checkout(): string {
        return this.basePath + 'shop/checkout';
    }
    success(): string {
        return this.basePath + 'shop/success/';
    }
    returnSuccess(id): string {
        return this.basePath + 'return-success/'+id;
    }

    wishlist(): string {
        return this.basePath + 'wishlist';
    }

    login(): string {
        const url = this.router.url;
        if (url !== '/login' && url !== '/en/login' && url !== '/ar/login') {
            localStorage.setItem('pageHistoryBeforeLogin', url);
        }
        return this.basePath + 'login';
    }

    terms(): string {
        return this.basePath + 'terms';
    }
    account(): string {
        return this.basePath + 'account/dashboard';
    }
    faqs(): string {
        return this.basePath + 'faqs';
    }
    paymentMethods(): string {
        return this.basePath + 'payment-methods';
    }
    pricingPolicy(): string {
        return this.basePath + 'pricing-policy';
    }
    privacyPolicy(): string {
        return this.basePath + 'privacy-policy';
    }
    returnPolicy(): string {
        return this.basePath + 'return-policy';
    }
    shippingPolicy(): string {
        return this.basePath + 'shipping-policy';
    }
    tradeInTerms(): string {
        return this.basePath + 'trade-in-terms';
    }
    categories(): string {
        return this.basePath + 'categories';
    }
    return(id): string {
        return this.basePath + 'return/'+id;
    }
    maintenance(): string {
        return this.basePath + 'maintenance';
    }
    brands(): string {
        return this.basePath + 'brands';
    }

    notFound(): string {
        return this.basePath + `/404`;
    }
}
