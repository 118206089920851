<main class="main home-page skeleton-body">
    <div class="icon-boxes-container bg-transparent">
        <div class="container">
            <div class="row desktop-flex">
                <div class="col-sm-6 col-lg-3" contentAnim aniName="fadeInLeftShorter">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon ">
                            <i class="icon-truck"></i>
                        </span>
                        <div class="icon-box-content">
                            <p>{{'blockfeature1' | translate}} {{'blockfeature2' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3" contentAnim aniName="fadeInLeftShorter">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon ">
                            <i class="icon-unlock"></i>
                        </span>

                        <div class="icon-box-content">
                            <p>{{'blockfeature9' | translate}} {{'blockfeature10' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3" contentAnim aniName="fadeInRightShorter">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon ">
                            <i class="icon-rocket"></i>
                        </span>
                        <div class="icon-box-content">
                            <p>{{'blockfeature5' | translate}} {{'blockfeature6' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3" contentAnim aniName="fadeInRightShorter">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon ">
                            <i class="icon-calendar"></i>
                        </span>
                        <div class="icon-box-content">
                            <p>{{'blockfeature7' | translate}} {{'blockfeature8' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile">
                <owl-carousel-o carouselClasses="" [options]="featuresSlider">
                    <ng-template carouselSlide>
                        <div class="icon-box icon-box-side">
                            <span class="icon-box-icon ">
                                <i class="icon-truck"></i>
                            </span>
                            <div class="icon-box-content">
                                <p>{{'blockfeature1' | translate}} {{'blockfeature2' | translate}}</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="icon-box icon-box-side">
                            <span class="icon-box-icon ">
                                <i class="icon-unlock"></i>
                            </span>

                            <div class="icon-box-content">
                                <p>{{'blockfeature9' | translate}} {{'blockfeature10' | translate}}</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="icon-box icon-box-side">
                            <span class="icon-box-icon ">
                                <i class="icon-rocket"></i>
                            </span>
                            <div class="icon-box-content">
                                <p>{{'blockfeature5' | translate}} {{'blockfeature6' | translate}}</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="icon-box icon-box-side">
                            <span class="icon-box-icon ">
                                <i class="icon-calendar"></i>
                            </span>
                            <div class="icon-box-content">
                                <p>{{'blockfeature7' | translate}} {{'blockfeature8' | translate}}</p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>
    </div>
    <div class="intro-slider-container mb-1 main-img-slider" *ngIf="slides?.length">
        <owl-carousel-o carouselClasses="" [options]="introSlider">
            <ng-template *ngFor="let slide of slides" carouselSlide>
                <a [href]="slide.link" class="intro-slide-link">
                    <div class="intro-slide intro-slide--desktop"
                        [ngStyle]="{'background-image': 'url('+slide['img_'+selected]+')'}"></div>
                    <div class="intro-slide intro-slide--mobile"
                        [ngStyle]="{'background-image': 'url('+slide['img_m_'+selected]+')'}"></div>
                </a>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="mb-4 desktop"></div>
    <div class="container">
        <!-- <h2 class="title text-center mb-0" contentAnim aniName="blurIn">{{'shopByCategory' | translate}}</h2> -->
        <div class="cat-blocks-container">
            <owl-carousel-o *ngIf="popularCategories?.length" carouselClasses="mt-3 mb-3 owl-simple owl-theme"
                [options]="categoriesSlider">
                <ng-template *ngFor="let category of popularCategories" carouselSlide>
                    <a [href]="root.category(category.slug)" class="cat-block">
                        <figure>
                            <span>
                                <img [src]="category.img" width="131" height="76" [alt]="category.title_en">
                            </span>
                        </figure>
                        <h3 class="cat-block-title">{{category['title_'+selected]}}</h3>
                    </a>
                </ng-template>

            </owl-carousel-o>
        </div>
    </div>
    <div class="mb-4 desktop"></div>
    <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('makita')">
                        <molla-image class="no-margin" src="assets/images/banners/threeblocks/makita.png" alt="Banner"
                            fixedPt="140px"></molla-image>
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('kc-power')">
                        <molla-image class="no-margin" src="assets/images/banners/threeblocks/kc_power.png" alt="Banner"
                            fixedPt="140px"></molla-image>
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('leo')">
                        <molla-image class="no-margin" src="assets/images/banners/threeblocks/leo.png" alt="Banner"
                            fixedPt="140px"></molla-image>
                    </a>
                </div>
            </div>


        </div>
    </div> -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6 col-lg-3">
                <div class="banner" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('makita')">
                        <img class="no-margin" src="assets/images/banners/threeblocks/makita-brand.webp"
                            alt="Cordless Tools">
                    </a>
                </div>
            </div>
            <div class="col-6 col-lg-3">
                <div class="banner" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('kc-power')">
                        <img class="no-margin" src="assets/images/banners/threeblocks/kc-power-brand.webp"
                            alt="Construction Tools">
                    </a>
                </div>
            </div>
            <div class="col-6 col-lg-3">
                <div class="banner" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.brand('leo')">
                        <img class="no-margin" src="assets/images/banners/threeblocks/leo-brand.webp" alt="Sawing">
                    </a>
                </div>
            </div>
            <div class="col-6 col-lg-3">
                <div class="banner" contentAnim aniName="fadeInLeftShorter">
                    <a [href]="root.bunch('leading-brands')">
                        <img class="no-margin" src="assets/images/banners/threeblocks/Leading-Brands.webp"
                            alt="Garden Machinery">
                    </a>
                </div>
            </div>


        </div>
    </div>
    <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image class="no-margin" *ngIf="selected==='en'" src="assets/images/banners/cordless/cordless-Drill-1-1.png" alt="Banner" fixedPt="160px"></molla-image>
                        <molla-image class="no-margin" *ngIf="selected==='ar'" src="assets/images/banners/cordless-Drill_Ar.png" alt="Banner" fixedPt="160px"></molla-image>
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image class="no-margin" *ngIf="selected==='en'" src="assets/images/banners/cordless/cordless-for-bulding-.png" alt="Banner" fixedPt="160px"></molla-image>
                        <molla-image class="no-margin" *ngIf="selected==='ar'" src="assets/images/banners/cordless/cordless-for-bulding-Ar.png" alt="Banner" fixedPt="160px"></molla-image>
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay banner-overlay-light" contentAnim aniName="fadeInLeftShorter">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image class="no-margin" *ngIf="selected==='en'" src="assets/images/banners/cordless/cordless-grinder.png" alt="Banner" fixedPt="160px"></molla-image>
                        <molla-image class="no-margin" *ngIf="selected==='ar'" src="assets/images/banners/cordless/cordless-grinder_Ar.png" alt="Banner" fixedPt="160px"></molla-image>
                    </a>
                </div>
            </div>


        </div>
    </div> -->

    <div class="mb-4 desktop"></div>
    <div class="mb-1 mobile"></div>

    <!-- <products-block-rows [products]="onlinex" header="{{'onlineExclusive' | translate}}" [loaded]="loaded"
        [max]="12"></products-block-rows> -->

    <div class="container bg-light" style="padding-bottom: 10px;" contentAnim *ngIf="brands && brands.length">
        <div class="heading heading-center" contentAnim aniName="blurIn">
            <h2 class="title font-weight-bolder">{{'shopByBrands'|translate}}</h2>
        </div>
        <owl-carousel-o carouselClasses="brands-border mt-1 mb-1 owl-simple owl-theme" [options]="brandSlider">
            <ng-template *ngFor="let brand of brands" carouselSlide>
                <a class="brand" [href]="root.brand(brand.slug)">
                    <img [src]="brand.imgfull" [alt]="brand.title_en">
                </a>
            </ng-template>

        </owl-carousel-o>
    </div>

    <div class="mb-2"></div>
    <products-block header="{{'bestselling' | translate}}" [products]="onlinex" [loaded]="loaded"></products-block>
    <!-- <products-block header="{{'onlineExclusive' | translate}}" [products]="onlinex" [loaded]="loaded"></products-block> -->



    <!-- Categories block images  -->
    <!-- <div class="container" contentAnim>
        <div class="banner-group banner-group-1 desktop">
            <div class="row ">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <figure class="banner banner-overlay banner-1" contentAnim aniName="fadeInRightShorter">
                                <a [href]="root.category('hand-tools')">
                                    <molla-image src="assets/images/home/banners/Hand_tools_690×340.webp" alt="Banner"
                                        width="690" height="340"></molla-image>
                                    <div class="banner-content">
                                        <h3 class="banner-title" *ngIf="selected==='en'">Hand Tools</h3>
                                        <h3 class="banner-title" *ngIf="selected==='ar'">أدوات يدوية</h3>
                                    </div>
                                </a>
                            </figure>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <figure class="banner banner-overlay banner-2" contentAnim aniName="fadeInUpShorter">
                                <a [href]="root.category('generators')">
                                    <molla-image src="assets/images/home/banners/Generators_335x340.webp" alt="Banner"
                                        width="335" height="340"></molla-image>

                                    <div class="banner-content">
                                        <h3 class="banner-title" *ngIf="selected==='en'">Generators</h3>
                                        <h3 class="banner-title" *ngIf="selected==='ar'">مولدات كهرباء</h3>
                                    </div>
                                </a>
                            </figure>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <figure class="banner banner-overlay banner-2" contentAnim aniName="fadeInUpShorter">
                                <a [href]="root.category('grinding')">
                                    <molla-image src="assets/images/home/banners/Grinding_335x340.webp" alt="Banner"
                                        width="335" height="340"></molla-image>

                                    <div class="banner-content">
                                        <h3 class="banner-title" *ngIf="selected==='en'">Grinding & Cutting Tools</h3>
                                        <h3 class="banner-title" *ngIf="selected==='ar'">أدوات الجلخ والقص</h3>
                                    </div>
                                </a>
                            </figure>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <figure class="banner banner-overlay banner-3" contentAnim aniName="fadeInLeftShorter">
                        <a [href]="root.category('carpentry')">
                            <molla-image src="assets/images/home/banners/Carpentry_690×700.webp" alt="banner"
                                width="690" height="700">
                            </molla-image>

                            <div class="banner-content">
                                <h3 class="banner-title" *ngIf="selected==='en'">Carpentry Tools</h3>
                                <h3 class="banner-title" *ngIf="selected==='ar'">معدات النجارة</h3>
                            </div>
                        </a>
                    </figure>
                </div>
            </div>
        </div>
        <div class="banner-group banner-group-1 mobile">
            <div class="row">
                <div class="col-6">
                    <figure class="banner banner-overlay banner-1" contentAnim aniName="fadeInRightShorter">
                        <a [href]="root.category('welding-solutions')">
                            <molla-image src="assets/images/home/banners/Hand_tools_mobile.webp"
                                alt="Banner"></molla-image>
                            <div class="banner-content">
                                <h3 class="banner-title" *ngIf="selected==='en'">Hand Tools</h3>
                                <h3 class="banner-title" *ngIf="selected==='ar'">أدوات يدوية</h3>
                            </div>
                        </a>
                    </figure>
                </div>
                <div class="col-6">
                    <figure class="banner banner-overlay banner-2" contentAnim aniName="fadeInUpShorter">
                        <a [href]="root.category('generators')">
                            <molla-image src="assets/images/home/banners/Generators_335x340.webp"
                                alt="Banner"></molla-image>

                            <div class="banner-content">
                                <h3 class="banner-title" *ngIf="selected==='en'">Generators</h3>
                                <h3 class="banner-title" *ngIf="selected==='ar'">مولدات كهرباء</h3>
                            </div>
                        </a>
                    </figure>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <figure class="banner banner-overlay banner-2" contentAnim aniName="fadeInUpShorter">
                        <a [href]="root.category('grinding')">
                            <molla-image src="assets/images/home/banners/Grinding_335x340.webp"
                                alt="Banner"></molla-image>

                            <div class="banner-content">
                                <h3 class="banner-title" *ngIf="selected==='en'">Grinding & Cutting Tools</h3>
                                <h3 class="banner-title" *ngIf="selected==='ar'">أدوات الجلخ والقص</h3>
                            </div>
                        </a>
                    </figure>
                </div>
                <div class="col-6">
                    <figure class="banner banner-overlay banner-3" contentAnim aniName="fadeInLeftShorter">
                        <a [href]="root.category('carpentry')">
                            <molla-image src="assets/images/home/banners/Carpentry_690×700.webp" alt="banner">
                            </molla-image>

                            <div class="banner-content">
                                <h3 class="banner-title" *ngIf="selected==='en'">Carpentry Tools</h3>
                                <h3 class="banner-title" *ngIf="selected==='ar'">معدات النجارة</h3>
                            </div>
                        </a>
                    </figure>
                </div>

            </div>
        </div>
    </div> -->


    <products-block-multi [products]="trendingProducts" [categories]="trendingCategories"
        header="{{'featuredProducts' | translate}}" [loaded]="loaded"></products-block-multi>

    <!-- <div class="mb-1"></div> -->

    <!-- <div class="container" *ngIf="selected === 'en'" contentAnim>
        <a [href]="root.category('cordless-tools')">
            <div class=" desktop adsBanner">
                <img src="assets/images/banners/fullwidth/Banner_Corless_Tools_English_desktop.webp">
            </div>
            <div class=" mobile">
                <img src="assets/images/banners/fullwidth/Banner_CorlessTools_English_mobile.webp">
            </div>
        </a>
    </div>
    <div class="container" *ngIf="selected === 'ar'" contentAnim>
        <a [href]="root.category('cordless-tools')">
            <div class=" desktop">
                <img src="assets/images/banners/fullwidth/Banner_Corless_Tools_Arabic_desktop.webp">
            </div>
            <div class=" mobile">
                <img src="assets/images/banners/fullwidth/Banner_Corless_Tools_Arabic_mobile.webp">
            </div>
        </a>
    </div> -->
    <!-- <div class="mb-2"></div> -->


    <!-- <products-block header="{{'featuredProducts' | translate}}" [products]="featuredProducts"
        [loaded]="featuredLoaded"></products-block> -->


    <div class="cta bg-image bg-dark pt-4 pb-5 mb-0">
        <!-- <div class="cta bg-image bg-dark pt-4 pb-5 mb-0" style="background-image: url('assets/images/home/bg-5.jpg')"> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-10 col-md-8 col-lg-6">
                    <div class="cta-heading text-center">
                        <h3 class="cta-title text-white">{{'subscribeText' | translate}}</h3>
                        <!-- <h3 class="cta-title text-white">{{'Newsletter' | translate}}</h3> -->
                        <!-- <p class="cta-desc text-white">{{'subscribeText' | translate}}</p> -->
                    </div>

                    <form>
                        <div class="input-group input-group-round">
                            <input type="email" name="email" [(ngModel)]="newsletter.email"
                                class="form-control form-control-white border-0"
                                placeholder="{{'EmailAddress' | translate}}" aria-label="Email Address" required>
                            <div class="input-group-append">
                                <button class="btn btn-primary" [ngClass]="{'btn-loading':!subscribeLoaded}"
                                    (click)="subscribe()" type="button">
                                    <span>{{'Subscribe' | translate}}</span>
                                    <i class="icon-long-arrow-right" *ngIf="selected==='en'"></i>
                                    <i class="icon-long-arrow-left" *ngIf="selected==='ar'"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<!-- https://aptools.sa/shop/checkout/943?paymentStatus=canceled&orderId=620b70d5-2e19-4343-b506-3757639504a6 -->