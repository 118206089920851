import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { AboutPageComponent } from '../about/about.component';
import { ContactPageComponent } from '../contact/contact.component';
import { FormsModule } from '@angular/forms';
import { TermsPageComponent } from '../terms/terms.component';
import { FaqsPageComponent } from '../faqs/faqs.component';
import { PrivacyPageComponent } from '../privacy/privacy.component';
import { PaymentMethodsPageComponent } from '../payment-methods/payment-methods.component';
import { PricingPolicyPageComponent } from '../pricing-policy/pricing-policy.component';
import { ReturnPolicyPageComponent } from '../return-policy/return-policy.component';
import { ShippingPolicyPageComponent } from '../shipping-policy/shipping-policy.component';
import { LoyalityPolicyPageComponent } from '../loyality-policy/loyality-policy.component';
import { WarrantyPlusTermsPageComponent } from '../warranty-plus-terms/warranty-plus-terms.component';
import { NationaldayPageComponent } from '../nationalday/nationalday.component';
import { StoresPageComponent } from '../stores/stores.component';
import { RetailPageComponent } from '../about/retail/retail.component';
import { OnlinePageComponent } from '../about/online/online.component';
import { DealersPageComponent } from '../about/dealers/dealers.component';
import { ProjectsPageComponent } from '../about/projects/projects.component';
import { FollowAndWinPageComponent } from '../follow-and-win/follow-and-win.component';
import { ReturnPageComponent } from '../return/return.component';
import { MaintenancePageComponent } from '../maintenance/maintenance.component';
import { BrandsPageComponent } from '../brands/brands.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ReturnSuccessComponent } from '../return-success/return-success.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TrackPageComponent } from '../track/track.component';

@NgModule({
	declarations: [
		PageNotFoundComponent,
		AboutPageComponent,
		RetailPageComponent,
		OnlinePageComponent,
		DealersPageComponent,
		ProjectsPageComponent,
		ContactPageComponent,
		StoresPageComponent,
		TermsPageComponent,
		FaqsPageComponent,
		PrivacyPageComponent,
		MaintenancePageComponent,
		PaymentMethodsPageComponent,
		PricingPolicyPageComponent,
		ReturnPolicyPageComponent,
		ShippingPolicyPageComponent,
		LoyalityPolicyPageComponent,
		WarrantyPlusTermsPageComponent,
		NationaldayPageComponent,
		FollowAndWinPageComponent,
		ReturnPageComponent,
		BrandsPageComponent,
		ReturnSuccessComponent,
		TrackPageComponent

	],

	imports: [
		CommonModule,
		PagesRoutingModule,
		SharedModule,
		NgbModule,
		RouterModule,
		HttpClientModule,
		HttpClientJsonpModule,
		NgbNavModule,
		TranslateModule,
		FormsModule,
		CarouselModule,
		GoogleMapsModule
	],
	providers: [NgbNavModule]
})

export class PagesModule { }
