import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { User } from 'src/app/shared/interfaces/user';
import { SessionService } from 'src/app/shared/services/session.service';
import { RootService } from 'src/app/shared/services/root.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Facebook } from 'src/app/shared/interfaces/facebook';
import { CookieService } from 'src/app/shared/services/cookie.service';

@Component({
	selector: 'molla-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit,OnDestroy {
	contractsHeader = false;
	@Input() containerClass = "container";
	selected: string;
	selectedLang: string;
	searchTerm = "";
	suggestions = [];
	timer: any;
	hasSuggestions = false;
    counter = 0;
    total = 0;
	company: any;
	languages = [
		{ name: 'English', code: 'en', direction: 'ltr', image: 'en.svg' },
		{ name: 'العربية', code: 'ar', direction: 'rtl', image: 'ar.svg' }
	];
	wishCount = 0;
	otherLang;
	qry = '';
	constructor(
		public activeRoute: ActivatedRoute, public utilsService: UtilsService, public modalService: ModalService,
		private lang: LanguageService,
		private router: Router,
		private session: SessionService,
		public root: RootService,
		public render: Renderer2,
		private auth: AuthService,
		private cookie:CookieService,
	) {
	}
	user: User;
	ngOnInit(): void {
		this.activeRoute.url.subscribe(urlSegment => {
			const currentUrl = this.router.url;
			if (currentUrl.includes('/contracts/')) {
				this.contractsHeader = true;
			  } else {
				this.contractsHeader = false;
			  }
		})
		this.user = this.session.getUser();
		this.selected = this.lang.selected;
		this.activeRoute.queryParams.subscribe(parameters => {
			this.qry = parameters.qry ?? '';
		});
		document.querySelector('body').addEventListener('click', this.closeSearchForm);
		let langObj = this.languages.find(i => i.code === this.selected);
		this.selectedLang = langObj ? langObj.name : '';
		this.otherLang = this.selected === 'en' ? this.languages[1] : this.languages[0];
	}
	ngOnDestroy(): void {
		document.querySelector('body').removeEventListener('click', this.closeSearchForm);
	}
	closeSearchForm() {
		if(document.querySelector('.header .header-search-mobile')){
			document.querySelector('.header .header-search-mobile').classList.remove('show');
		}
	}
	searchProducts(event: any) {
		this.searchTerm = event.target.value;
		this.fetchProducts();
	}
	fetchProducts() {
		if (this.searchTerm.length > 2) {
			if (this.timer) {
				window.clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				this.auth.getSuggestions({'qry':this.searchTerm}).subscribe(products => {
					this.hasSuggestions = products.items?.length > 0;
					this.counter = products.counter;
					this.total = products.total;
					if (products.items?.length > 0) {
						this.suggestions = products.items;
					}
				});
				// add facebook Event Server Side
				let facebook: Facebook = {};
				facebook.fbc = this.cookie.getCookie('_fbc');
				facebook.fbp = this.cookie.getCookie('_fbp');
				facebook.user = this.session.getUser() ?? null;
				facebook.event_name = "Search";
				this.auth.addFacebookEvent(facebook).subscribe(res => {
				console.log(res);
				});
			}, 500)
		} else {
			window.clearTimeout(this.timer);
			this.suggestions = [];
		}
	}
	matchEmphasize(name: string) {
		var regExp = new RegExp(this.searchTerm, 'i');
		return name.replace(
			regExp,
			match => '<strong>' + match + '</strong>'
		);
	}
	goProductPage() {
		this.searchTerm = '';
		this.suggestions = [];
		var inputElement: any = document.querySelector('.header-search-mobile .form-control');
		inputElement.value = "";
		this.closeSearchForm();
	}
	searchToggle(e: Event) {
		document.querySelector('.header-search-mobile').classList.toggle('show');
		e.stopPropagation();
	}

	showSearchForm(e: Event) {
		document
			.querySelector('.header .header-search-mobile')
			.classList.add('show');
		e.stopPropagation();
	}

	saveSearch(): void {
        let user = this.session.getUser();
        let data = {
            'products': this.total,
            'user': user ? user.id : 0,
            'qry': this.searchTerm
        };
        this.auth.saveSearch(data).subscribe();
    }
    search(): void {
        this.suggestions = [];
		console.log('search');
		var inputElement: any = document.querySelector('.header-search-mobile .form-control');
		inputElement.value = "";
		this.closeSearchForm();
        if (this.searchTerm) {
            this.saveSearch();
            if (this.company && this.company.allow_slaps == 1) {
                this.router.navigateByUrl('/company?page=1&qry=' + this.searchTerm);
            } else {
                this.router.navigateByUrl('/shop?page=1&qry=' + this.searchTerm);
            }
        }
    }




	showLoginModal(event: Event): void {
		event.preventDefault();
		this.modalService.showLoginModal();
	}
	changeLanguage(lng) {
		let oldLng = lng === 'en' ? 'ar' : 'en';
		let link = this.router.url.toString().split('?')[0];
		let parameters = this.router.url.toString().split('?')[1] ? '?' + this.router.url.toString().split('?')[1] : '';
		link = link === '/' ? '' : link;
		if (link.includes('/' + oldLng)) {
			this.router.navigateByUrl(link.replace('/' + oldLng, '/' + lng) + parameters);
		} else if (link.includes('/' + lng)) {
			// do nothing encodeURIComponent
		} else {
			this.router.navigateByUrl('/' + lng + link + parameters);
		}
	}
	submitSearchForm(e: Event) {
		e.preventDefault();
		this.router.navigate(['/shop'], { queryParams: { page: 1, qry: this.searchTerm }, queryParamsHandling: 'merge' });
	}
	toggleSearch(event: any) {
		const body = document.querySelector('body');
		const html = document.querySelector('html');

		if (body.classList.contains('search-active') || event.target.classList.contains('mobile-search-container')) {
			this.render.removeClass(body, 'search-active');
			this.render.removeAttribute(html, 'style')
		} else {
			this.render.addClass(body, 'search-active');
			this.render.setStyle(html, 'overflow-x', 'hidden');
		}
	}
	hideSearch(event: any) {

	}
}
