import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { Observable } from 'rxjs';
// import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-stores',
	templateUrl: './stores.component.html',
	styleUrls: ['./stores.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoresPageComponent implements OnInit {
	selected;
	loaded:boolean = false;
	stores = [
		{
			name_en : "Khobar Branch",
			name_ar : "فرع الخبر",
			address_en : "King Khaled Street, Al Khobar Al Shamalia, Al Khobar 34427",
			address_ar : "الخبر - حي السلام شارع الملك خالد",
			city : "Khobar",
			time : "8:00 -12:00 / 16:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.234477352792!2d50.2145327!3d26.286497699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e900329164bd%3A0x71b369a055937f8!2sArab%20Pros%20for%20Machinery%20and%20Equipment!5e0!3m2!1sen!2ssa!4v1708923824455!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0138203898", 
			mobile : "0502537338",
			email : "khn@arabprotools.com"
		},
		{
			name_en : "AP Khodariya D1 Branch",
			name_ar : "فرع الدمام",
			address_en : "الشارع التاسع ، حي, Al Muhammadiyah, Dammam 32415",
			address_ar : "الدمام - الخظرية الشارع التاسع",
			city : "Dammam",
			time : "8:00 - 19:30",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28578.814083214125!2d50.05647100000001!3d26.444388!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fd1682144805%3A0xf370fe7f2ebdd73a!2zQXJhYiBQcm9zIFRvb2xzINi02LHZg9ipINin2YTZhdit2KrYsdmB2YjZhiDYp9mE2LnYsdio!5e0!3m2!1sen!2ssa!4v1708866936353!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			mobile : "0553049998",
			email : "d1n@arabprotools.com"
		},

		// style="max-width: 100%;height: 100%;border:0"
		{
			name_en : "Riyadh Main R1 Branch",
			name_ar : "فرع الرياض",
			address_en : "Al Amal, Riyadh 12643",
			address_ar : "الرياض - شارع الريل ",
			city : "Riyadh",
			time : "8:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d453.3073801553638!2d46.72329903600001!3d24.64232297806086!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05ed3ec41f85%3A0xb1f6b54a40be7acd!2zQXJhYiBQcm9zIFRvb2xzINi02LHZg9ipINin2YTZhdit2KrYsdmB2YjZhiDYp9mE2LnYsdio!5e0!3m2!1sen!2ssa!4v1708869649930!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0112633954",
			mobile : "0547275825",
			email : "r1n@arabprotools.com"
		},
		{
			name_en : "Jeddah 4 Branch",
			name_ar : "فرع جده ",
			address_en : "Al Baladeah, Aziziyah, Jeddah 23333",
			address_ar : "جده - العزيزية منطقة البلدية",
			city : "Jeddah",
			time : "8:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3754.009845647982!2d39.18398750832819!3d21.551107105029658!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d03a584a1599%3A0x8f846fb5d0156556!2sArab%20Pros%20for%20Machinery%20and%20Equipment%20(Khusheim)!5e0!3m2!1sen!2ssa!4v1708870382503!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0126591500",
			mobile : "0582956234",
			email : "j4n@arabprotools.com"
		},
		{
			name_en : "Hassa Branch",
			name_ar : "فرع الاحساء",
			address_en : "Prince Abdullah Ibn Jalawy Street , Al Mubarraz 36341",
			address_ar : "الاحساء - المبرز شارع الامير عبدالله بن جلوي",
			city : "Al Hassa",
			time : "8:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1152.8721132843432!2d49.58290331298076!3d25.398332170015195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e3797f9df08958b%3A0xe3352928cded22b7!2z2LTYsdmD2Kkg2KfZhNmF2K3Yqtix2YHZiNmGINij2YTYudix2Kgg2YTZhNii2YTYp9iqINmI2KfZhNmF2LnYr9in2Ko!5e0!3m2!1sar!2ssa!4v1708870496768!5m2!1sar!2ssa" style="max-width: 100%;height: 100%;border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0135847880",
			mobile : "0501904057",
			email : "hsn@arabprotools.com"
		},
		{
			name_en : "Buraida Branch",
			name_ar : "فرع بريده",
			address_en : "An Naziyah, Buraydah 52359",
			address_ar : "يريدة - حي النازية شارع محمد بن صلاح الوهيبي",
			city : "Buraydah",
			time : "8:00 -12:00 <br> 16:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d114418.7461151875!2d43.95889100000001!3d26.339908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f59826b27b17f%3A0x69629a6e315a7a13!2z2LTYsdmD2Kkg2KfZhNmF2K3Yqtix2YHZiNmGINin2YTYudix2Kgg2YTZhNin2YTYp9iqINmI2KfZhNmF2LnYr9in2Kog2KjYsdmK2K_YqSjYrti02YrZhSk!5e0!3m2!1sen!2ssa!4v1708870567668!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "016 324 6033",
			mobile : "055 967 6886",
			email : "brn@arabprotools.com"
		},
		{
			name_en : "Jeddah Main Branch",
			name_ar : "فرع جده - طريق المدينة",
			address_en : "6673 Al Madina al Monawara highway 2264، Jeddah 23216 , El Sharafya",
			address_ar : "جده - طريق المدينة المنورة، حي الشرفية",
			city : "Jeddah",
			time : "8:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1182.7195327094528!2d39.181856122277836!3d21.51660090694167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cfb25877fc77%3A0x1811908da1bb44c0!2zQXJhYiBQcm9zIFRvb2xzINi02LHZg9ipINin2YTZhdit2KrYsdmB2YjZhiDYp9mE2LnYsdio!5e0!3m2!1sen!2ssa!4v1708869830473!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0122721050",
			mobile : "0580306633",
			email : "jnn@arabprotools.com"
		},
		{
			name_en : "Madina  Branch",
			name_ar : "فرع المدينة المنورة",
			address_en : "8702 King Abdullah Branch Rd،  Madinah Al Munawwarah 42382",
			address_ar : "المدينة المنوره - حي السد طريق الملك عبدالله ",
			city : "Al Madina",
			time : "8:00 -12:00 / 16:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2828.4414645277325!2d39.62759018700389!3d24.41988700313727!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bdc144df33d079%3A0x3a7f78868fbc10aa!2zQXJhYiBQcm9zIFRvb2xzINi02LHZg9ipINin2YTZhdit2KrYsdmB2YjZhiDYp9mE2LnYsdio!5e0!3m2!1sen!2ssa!4v1708870709802!5m2!1sen!2ssa" style="max-width: 100%;height: 100%;border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0148347920",
			mobile : "0503286773",
			email : "mdn@arabprotools.com"
		},
		{
			name_en : "Khamis Masheit Branch",
			name_ar : "فرع خميس مشيط",
			address_en : "Al Salam  - opposite Riyad Bank, Khamis Mushait - King Fahd Road - Al Salam District, Khamis Mushait",
			address_ar : " حي السلام- مقابل بنك الرياض، خميس مشيط- طريق الملك فهد - حي السلام، خميس مشيط ",
			city : "Khamis Mushait",
			time : "8:00 - 20:00",
			location : '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15151.62867528678!2d42.721678!3d18.305853!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb5b8d8a8b555b%3A0x3cf2c4e830cb2f61!2z2LTYsdmD2Kkg2KfZhNmF2K3Yqtix2YHZiNmGINin2YTYudix2Kgg2YTZhNii2YTYp9iqINmI2KfZhNmF2LnYr9in2Ko!5e0!3m2!1sar!2ssa!4v1708870632236!5m2!1sar!2ssa" style="max-width: 100%;height: 100%;border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			phone : "0172240172",
			mobile : "0509089456",
			email : "kmn@arabprotools.com"
		},
	];
	constructor(
		private lang: LanguageService,
		public root: RootService,
		private sanitizer: DomSanitizer
	) {
		
	}
	sanitize(content){
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
	ngOnInit() {
		this.selected = this.lang.selected;
		this.loaded = false;
	}
}
