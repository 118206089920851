import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-about-page',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AboutPageComponent implements OnInit {
	selected;
	items = [
		{
			title_en: "Power Tools",
			title_ar: "أدوات كهربائية",
			img: "powertools.png",
		},
		{
			title_en: "CONSTRUCTION EQUIPMENT",
			title_ar: "معدات البناء",
			img: "construction.png",
		},
		{
			title_en: "SAFETY EQUIPMENT",
			title_ar: "معدات السلامة",
			img: "safety.png",
		},
		{
			title_en: "WATER PUMPS",
			title_ar: "مضخات المياه",
			img: "Untitled-3-11.png",
		},
		{
			title_en: "LIFTING EQUIPMENT",
			title_ar: "معدات الرفع",
			img: "lifting.png",
		},
		{
			title_en: "HIGH PRESSURE WASHER",
			title_ar: "غسالات ضغط عالي",
			img: "highpressurewater.png",
		},
		{
			title_en: "GARDENING TOOLS",
			title_ar: "أدوات الحدائق",
			img: "gardening.png",
		},
		{
			title_en: "PNEUMATIC TOOLS",
			title_ar: "أدوات هوائية",
			img: "pneumatic.png",
		},
		{
			title_en: "WELDING EQUIPMENT",
			title_ar: "معدات اللحام",
			img: "welding.png",
		},
		{
			title_en: "VACUUM CLEANER",
			title_ar: "مكنسة كهربائية",
			img: "vacuum.png",
		},
		{
			title_en: "GENERATORS PETROL & DIESEL",
			title_ar: "مولدات البنزين والديزل",
			img: "generators.png",
		},
	];

	
brands = [
    {
        name: "ARAMCO",
        image: "assets/images/about/logos/ARAMCO.png"
    },
    {
        name: "Sabic",
        image: "assets/images/about/logos/Sabic.png"
    },
    {
        name: "sadara",
        image: "assets/images/about/logos/sadara.png"
    },
    {
        name: "Maaden",
        image: "assets/images/about/logos/Maaden.png"
    },
]
clients = [
    {
        name: "kadeya",
        image: "assets/images/about/logos/kadeya.png"
    },
    {
        name: "redsea",
        image: "assets/images/about/logos/redsea.png"
    },
    {
        name: "neom",
        image: "assets/images/about/logos/neom.png"
    },
    {
        name: "diriyah",
        image: "assets/images/about/logos/diriyah.png"
    },
    {
        name: "expertise",
        image: "assets/images/about/logos/expertise.png"
    },
    {
        name: "nesma",
        image: "assets/images/about/logos/nesma.png"
    },
    {
        name: "El Seif",
        image: "assets/images/about/logos/elseif.png"
    },
    {
        name: "Man Enterprise",
        image: "assets/images/about/logos/man.png"
    },
]
	constructor(
		public sanitizer: DomSanitizer,
		private lang : LanguageService,public root:RootService) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
}
